<template> 
  <div class="form-group"  :class="{'hide': (!props.show)}">
    <label :for="inputId" class="contact-form-label">
      {{ labelText }}
      <span v-if="!props.isRequired" class="optional-text"> (optional)</span>
    </label>
    <textarea class="contact-form-textarea" rows="5"
						:id="inputId"
						:placeholder="placeholderText"
						:v-model="modelValue"
						:required="isRequired"
						:maxlength="getLimit()"
						@input="handleInput"></textarea>
    <p :id="`${inputId}-error`" class="gibs-form-group-error gibs-small-regular"></p>
    <p class="hint-text">{{ hint }}</p>
    <div v-if="props.inputId === 'password'" class="text-xs1 text-wildblueyonder">
      <strong>Note:</strong> The password must contain a <strong>minimum of 8 characters</strong> with <strong>1 Uppercase, 1 Lowercase, 1 Number</strong>, and <strong>1 Special character.</strong>
  </div>
  </div>
</template>

<script setup lang="ts">


	const props = defineProps({
		inputId: {
			type: String,
			required: true,
		},
		labelText: {
			type: String,
			required: true,
		},
		placeholderText: {
			type: String,
			default: '',
		},
		hint: {
			type: String,
			default: '',
		},
		maxLength: {
			type:  Number,
			default: -1,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: [String, Number],
			default: '',
		},
		// validators: {
		//   type: Array<Function>,
		//   default: new Array<Function>()
		// },
		validator: {
			type: Validation,
			default: null
		},
		validatorRuleId: {
			type: String,
			default: null
		},
		show: {
			type: Boolean,
			default: true
		}
	});

	const showPassword = ref(false);

	const inputValue = ref('');

	const handleInput = (event: InputEvent) => {
	inputValue.value = (event.target as HTMLInputElement).value;

	if (typeof(props.validator) !== 'undefined' && props.validator != null) { 
		props.validator.setValidationRuleSourceValue(props.validatorRuleId, inputValue.value);
		let resultList = props.validator.validateRule(props.validatorRuleId);
		ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId), resultList);
	}


	emitFormInput('input', inputValue.value); // Emit the input value
	};

	const emitFormInput = defineEmits<{
	input: [email: string]
	}>(); 

	function togglePasswordVisibility() {
		showPassword.value = !showPassword.value;
		const input:HTMLInputElement = document.getElementById(props.inputId) as HTMLInputElement;
		if (input) {
			input.type = showPassword.value ? 'text' : 'password';
		}
	}

	function getLimit(){
		let output = '';
		if(props.maxLength != -1){
			output = props.maxLength.toString();
		}
		return output;
	}
	
	function ApplyValidate(isInvalid: boolean, validationResultList: Array<IValidationResult>) {
		const inputElement = document.getElementById(props.inputId) as HTMLElement;
		const errorElement = document.getElementById(`${props.inputId}-error`) as HTMLElement;
		
		if (isInvalid) {
		inputElement.classList.add("has-error");
		}
		else {
		inputElement.classList.remove("has-error");
		}
		
		let errorString = '';
		if (typeof (props.validator) !== 'undefined' && props.validator != null) {
		errorString = props.validator.getValidationResultString(props.validatorRuleId);
		}
		errorElement.innerText = errorString;
	}
</script>

